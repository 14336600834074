:root {
  /* Colors */
  --primary-color: #2C302E;
  --secondary-color: #6D9C76;
  --neutral-light: #ACAFAC;
  --neutral-dark: #474A48;
  --accent-color: #9AE19D;
  /* Fonts */
  --heading-font: 'Playfair Display', serif;
  --body-font: 'Lato', sans-serif;
  /* Layout */
  --spacing-unit: 2px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--neutral-light);
  color: var(--neutral-dark);
  line-height: 1.6;
  padding: var(--spacing-unit);
  font-family: var(--body-font);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-color);
  font-family: var(--heading-font);
  margin: var(--spacing-unit);
}

h1,
h2 {
  text-align: center;
}

p {
  color: var(--neutral-dark);
  font-family: var(--body-font);
}

a {
  color: var(--primary-color);
  font-family: var(--body-font);
  text-decoration: none;
}

a:hover {
  color: var(--accent-color);
}

ul {
  list-style-type: none;
  padding: 0;
}

/* button {
  background-color: var(--primary-color);
  color: var(--accent-color);
  border: none;
  padding: 10px 20px;
} */

/* button.secondary {
  background-color: var(--secondary-color);
  color: var(--accent-color);
} */

/* Banner */
.banner {
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
}

.banner-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  /* This will make the content take up all remaining space */
}

.banner-overlay h1 {
  text-align: center;
}

.banner-title {
  color: white;
}

.banner-subtitle {
  color: var(--neutral-light);
}

/* Navbar */
.navbar {
  font-family: var(--heading-font);
  width: 100%;
}

.navbar-brand {
  min-width: 100px;
  white-space: nowrap;
}

.navbar-collapse {
  top: 100%;
  width: 100%;
}

.nav-link {
  color: white;
  font-size: large;
}

.nav-link:hover {
  color: var(--neutral-light)
}

@media (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    width: 100%;
  }
}

/* Cards */
.project-card {
  width: 20rem;
  background-color: var(--primary-color);
  color: white;
  height: 450px;
  margin: 10px 20px;
}

.project-card .project-card-button {
  background-color: var(--secondary-color);
  width: 40%;
  border: none;
}

.project-card .project-card-button:hover {
  background-color: var(--secondary-color);

}

.project-card .project-card-body {
  text-align: center;
}

.contact-card {
  width: 100%;
  background-color: var(--primary-color);
  color: white;
}

.contact-card p {
  color: white;
}

/* Footer */
.MyFooter {
  display: flex;
  background-color: var(--primary-color);
  color: var(--neutral-light);
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.MyFooter p,
.MyFooter ul {
  margin: 0;
  color: var(--neutral-light)
}

.MyFooter .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MyFooter .footer-links {
  list-style: none;
  padding: 0;
}

.MyFooter .footer-links li {
  display: inline;
  margin: 0 10px;
}

.MyFooter .footer-links a {
  color: var(--neutral-light);
  text-decoration: none;
}

.MyFooter .footer-links a:hover {
  color: var(--neutral-dark);
}